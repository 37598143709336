import React, {Component} from 'react';
import Config from "../../../config";
import ActionToolbar from "../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../common/button/button-general";
import GridContainer from "../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import UserImage from "../../common/user/user-image";
import UserName from "../../common/user/user-name";
import {Col, FormGroup, Image, Row} from "react-bootstrap";
import Status from "../../common/status/status";
import IconButton from "@material-ui/core/IconButton";
import Icons from "../../common/icons";
import Divider from "@material-ui/core/Divider";
import Filter from "../../filter/filter";
import {Combo} from "../../common/form-material";
import DateBoxPicker from "../../common/form-material/date-box";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../redux/general/general_actions";
import {withStyles} from "@material-ui/core";
import _ from "lodash";
import Modal from "../../common/modal/modal";
import Api from "../../../services/api";
import W52F1001 from "../W52F1001/W52F1001";
import * as W75F2010Actions from "../../../redux/W75/W75F2010/W75F2010_actions";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    },
    dateInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: '#575757',
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: '#000000',
    }
};

class W52F1000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            dataGrid: {
                rows: [],
                total: 0,
            },
            loadingCboProject: false,
            loadingCboEmployee: false,
            loadingDataGrid: false,
            mode: "add",
            rowData: null,
            showW52F1001: false,
            DateFrom: null,
            DateTo: null,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboProjects = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterDataGrid = {
            Employee: "",
            Project: "",
            PeriodTime: "",
            AppStatusID: "%",
            skip: 0,
            limit: 10,
        }
        this.params = {
            FormID: "W52F1000",
            Language: Config.language || 84,
        }
        this.handoverSelected = [];
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W52F1000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    getData = (data, isReset = false) => ({
        total: _.get(data, "total", _.get(data, "length", 0)),
        rows: isReset ? this.state.dataCboEmployees.rows.concat(_.get(data, "rows", data)) : _.get(data, "rows", data)
    });

    loadDataGrid = async (voucher_id) => {
        const {DateFrom, DateTo} = this.state;
        const {voucher_id: _voucher_id} = Config.getUrlParams(this.props);
        voucher_id = voucher_id ? voucher_id : _voucher_id;
        const params = {
            ...this.params,
            ..._.pick(this.filterDataGrid, ["AppStatusID", "PeriodTime", "skip", "limit"]),
            EmployeeID: this.filterDataGrid.Employee.EmployeeID,
            ProjectID: this.filterDataGrid.Project.ProjectID,
            TransID: voucher_id || "",
            DateFrom,
            DateTo
        }
        this.setState({loadingDataGrid: true});
        const res = await Api.put("/w52f1000/load-form", params);
        this.setState({loadingDataGrid: false});
        const errMessage = _.get(res, "message", "");
        const data = _.get(res, "data", "");
        if (errMessage) {
            Config.popup.show("ERROR", errMessage);
            return false;
        }
        if (data) {
            this.setState({dataGrid: this.getData(data)})
        }
    }

    loadCboEmployees = (isReset = false) => {
        const {dataCboEmployees} = this.state;
        const params = {
            ...this.params,
            Type: "EmployeeID",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (err, data) => {
            this.setState({loadingCboEmployee: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0))
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset = false) => {
        const {dataCboProjects} = this.state;
        const params = {
            ...this.params,
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setState({loadingCboProject: true});
        this.props.generalActions.getCboProjects(params, (err, data) => {
            this.setState({loadingCboProject: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0));
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total,
                    }
                })
            }
        });
    };

    loadCboPeriod = () => {
        const param = {
            DivisionID: "QT",
        };
        this.props.W75F2010Actions.loadPeriod(param, (err) => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    };

    loadCboAppStatus = () => {
        this.props.generalActions.getCboAppStatus({...this.params, FormID: "STANDARD"}, (err) => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    };

    loadCboAbsentType = () => {
        this.props.W75F2010Actions.loadAbsentType((err) => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.state;
        Config.callChildForm({
            FormID: "W52F1000",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => {
                if (_.get(data, "AppStatusID", 0) !== 0) {
                    this.onView({row: {data: data}});
                } else {
                    this.onEdit({row: {data: data}});
                }
            }
        }, this.props);
    }

    onView = (e) => {
        const {data} = e.row;
        this.setState({
            mode: "view",
            rowData: data,
            showW52F1001: true
        });
    }

    onAdd = () => {
        this.setState({
            mode: "add",
            showW52F1001: true
        });
    };

    onEdit = (e) => {
        const {data} = e.row;
        this.setState({
            mode: "edit",
            rowData: data,
            showW52F1001: true
        });
    };

    onCloseModal = (isReloadForm = false) => {
        if (isReloadForm) {
            this.loadDataGrid();
        }
        this.setState({
            showW52F1001: false
        });
    };

    onDeleteRow = (data) => {
        const params = {
            ...this.params,
            TransID: _.get(data, "TransID", ""),
            EmployeeID: _.get(data, "EmployeeID", "")
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            (async () => {
                this.setState({ loadingDataGrid: true });
                const res = await Api.put("/w52f1000/delete", params);
                this.setState({ loadingDataGrid: false });
                const data = _.get(res, "data", {});
                const errMessage = _.get(res, "message", _.get(data, "Message", "") || Config.lang("Loi_khong_xac_dinh"));
                const status = _.get(data, "Status", 1);
                if (errMessage || status) {
                    Config.popup.show("ERROR", errMessage);
                    return false;
                }
                if (!_.isEmpty(data)) {
                    const dataMessage = _.get(data, "Message", Config.lang("Loi_chua_xac_dinh"));
                    const dataStatus = _.get(data, "Status", 1);
                    if (dataMessage || dataStatus) {
                        Config.popup.show("ERROR", dataMessage);
                        return false;
                    }
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                    this.loadDataGrid();
                }
            })();
        });
    }

    onFilterChange = (key, data) => {
        let value = _.get(data, "value", "");
        if (key === "DateFrom" || key === "DateTo") {
            this.setState({[key]: value});
            return false;
        }
        this.filterDataGrid[key] = value
    }

    onChangePage = (page) => {
        this.filterDataGrid.skip = page * this.filterDataGrid.limit;
        this.loadDataGrid();
    };

    onChangePerPage = (perPage) => {
        this.filterDataGrid.skip = 0;
        this.filterDataGrid.limit = perPage;
        this.loadDataGrid();
    };

    onFilter = () => {
        this.loadCboEmployees();
        this.loadCboAbsentType();
        this.loadCboProjects();
        this.loadCboAppStatus();
        this.loadCboPeriod();
    }

    onSearch = () => {
        this.loadDataGrid();
    };

    renderEmpProfile = (e) => {
        const {classes} = this.props;
        const {data} = e.row;
        const date = Config.convertDate(data.EntryDate, "", "LT, DD/MM/YYYY", true);
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.dateInfo}>
                        <Image src={require('../../../assets/images/icon-calendar.svg')}/>
                        <div style={{paddingLeft: '8px'}} className={"date-text-info"}>{data.EntryDate && date}</div>
                    </div>
                </div>
            </div>
        )
    };

    renderInfo = (e) => {
        const {classes} = this.props;
        const {data} = e.row;
        const arrDataField = _.filter([
            {
                label: Config.lang("Du_an"),
                value: _.get(data, "ProjectID", "") ? _.get(data, "ProjectName", "") : "",
            },
            {
                label: Config.lang("Ban_giao"),
                value: _.get(data, "HandOverID", "") ? _.get(data, "HandOverName", "") : "",
            },
            {
                label: Config.lang("Ky_luong"),
                value: _.get(data, "Period", ""),
            },
            {
                label: Config.lang("Ghi_chu"),
                value: _.get(data, "Note", ""),
            },
            {
                label: Config.lang("Nguoi_lap"),
                value: _.get(data, "CreateUserName", ""),
            },
            {
                label: Config.lang("Nguoi_duyet"),
                value: _.join(_.compact([
                    _.get(data, "ApproverName", ""),
                    _.get(data, "DepartmentID", ""),
                    Config.convertDate(_.get(data, "ApprovalDate", ""), "", "LT, DD/MM/YYYY", true)
                ]), " - ")
            },
            {
                label: Config.lang("Ghi_chu_duyet"),
                value: _.get(data, "ApproverNote", "")
            }
        ], item => Boolean(item.value));
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {_.map(arrDataField, (item, i) =>
                    <div key={i}>
                        {_.join([item.label, item.value], ": ")}
                    </div>
                )}
            </div>
        )
    };

    renderStatus = (e) => {
        const {data} = e.row;
        return (
            <Status data={data}/>
        );
    };

    renderAction = (e) => {
        const {data} = e.row;
        const {iPermission} = this.state;
        return (
            <div className={"display_row align-center"}>
                <IconButton disabled={iPermission < 1} aria-label="view" size="small" onClick={() => this.onView(e)}>
                    <Icons name={"view"}/>
                </IconButton>
                <Divider orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}/>
                <IconButton disabled={(data && Number(data.AppStatusID) !== 0) || iPermission < 3} aria-label="delete"
                            size="small"
                            onClick={() => this.onDeleteRow(data)}>
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        )
    };

    renderFilter = () => {
        const {getCboPeriod, getCboAppStatus, DateTo, DateFrom, classes} = this.props;
        const {PeriodTime, Project, AppStatusID, Employee} = this.filterDataGrid;
        const {dataCboEmployees, dataCboProjects, loadingCboProject, loadingCboEmployee} = this.state;
        return (
            <Filter
                onOpened={this.onFilter}
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={
                    () => {
                        return (
                            <>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo
                                                label={Config.lang("Ky_luong")}
                                                dataSource={getCboPeriod}
                                                displayExpr={"PeriodTime"}
                                                valueExpr={"PeriodTime"}
                                                onValueChanged={(e) => this.onFilterChange("PeriodTime", e)}
                                                showClearButton={true}
                                                value={PeriodTime}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <div className={classes.divText}>{Config.lang("Tu")}</div>
                                                <DateBoxPicker
                                                    max={DateTo}
                                                    style={{marginLeft: "5px"}}
                                                    onValueChanged={(e) => this.onFilterChange("DateFrom", e)}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    placeholder={"DD/MM/YYYY"}
                                                    value={DateFrom}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <div
                                                    className={classes.divText}>{Config.lang("Den").toLowerCase()}</div>
                                                <DateBoxPicker
                                                    min={DateFrom}
                                                    style={{marginLeft: "5px"}}
                                                    onValueChanged={(e) => this.onFilterChange("DateTo", e)}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    placeholder={"DD/MM/YYYY"}
                                                    value={DateTo}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo
                                                label={Config.lang("Trang_thai_duyet")}
                                                dataSource={getCboAppStatus}
                                                displayExpr={"AppStatusName"}
                                                valueExpr={"AppStatusID"}
                                                onValueChanged={(e) => this.onFilterChange("AppStatusID", e)}
                                                showClearButton={true}
                                                value={AppStatusID}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo.LoadMore
                                                dataSource={dataCboEmployees.rows}
                                                skip={this.filterCboEmployees.skip}
                                                limit={this.filterCboEmployees.limit}
                                                displayExpr={"EmployeeName"}
                                                keyExpr={'EmployeeID'}
                                                valueExpr={'EmployeeID'}
                                                value={Employee}
                                                stylingMode={'outlined'}
                                                loading={loadingCboEmployee}
                                                label={Config.lang('Nhan_vien')}
                                                itemRender={(e) => {
                                                    const {data} = e;
                                                    if (!data) return null;
                                                    return data.EmployeeID + ' - ' + data.EmployeeName;
                                                }}
                                                showClearButton={true}
                                                onValueChanged={e => this.onFilterChange("Employee", e)}
                                                onInputChanged={(e) => {
                                                    this.filterCboEmployees.strSearch = e.target.value;
                                                    this.filterCboEmployees.skip = 0;
                                                    this.loadCboEmployees(true);
                                                }}
                                                onLoadMore={(e) => {
                                                    this.filterCboEmployees.skip = e.skip;
                                                    this.filterCboEmployees.limit = e.limit;
                                                    this.loadCboEmployees();
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo.LoadMore
                                                dataSource={dataCboProjects}
                                                skip={this.filterCboProjects.skip}
                                                limit={this.filterCboProjects.limit}
                                                displayExpr={'ProjectName'}
                                                keyExpr={'ProjectID'}
                                                valueExpr={'ProjectID'}
                                                value={Project}
                                                loading={loadingCboProject}
                                                stylingMode={'outlined'}
                                                label={Config.lang('Du_an')}
                                                itemRender={(e) => {
                                                    const {data} = e;
                                                    if (!data) return null;
                                                    return data.ProjectID + ' - ' + data.ProjectName;
                                                }}
                                                showClearButton={true}
                                                // showCheckBoxMode={"none"}
                                                onValueChanged={e => this.onFilterChange("Project", e)}
                                                onInputChanged={(e) => {
                                                    this.filterCboProjects.strSearch = e.target.value;
                                                    this.filterCboProjects.skip = 0;
                                                    this.loadCboProjects(true);
                                                }}
                                                onLoadMore={(e) => {
                                                    this.filterCboProjects.skip = e.skip;
                                                    this.filterCboProjects.limit = e.limit;
                                                    this.loadCboProjects();
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    paddingTop: "38px",
                                    paddingBottom: "40px"
                                }}>
                                    <ButtonGeneral
                                        name={Config.lang("Tim_kiem")}
                                        typeButton={"search"}
                                        size={"large"}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={this.onSearch}
                                    />
                                </div>
                            </>
                        )
                    }
                }
            />
        )
    };

    render() {
        const {dataGrid, showW52F1001, rowData, mode, iPermission, loadingDataGrid} = this.state;
        const {skip, limit} = this.filterDataGrid;
        if (iPermission <= 0) return null;
        return (
            <>
                <Modal open={showW52F1001} maxWidth={"md"} fullWidth={true}
                       title={Config.lang("Sai_sot_cong")}
                       onClose={() => this.onCloseModal()}>
                    <W52F1001 mode={mode} data={rowData} onClose={this.onCloseModal}/>
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar
                    title={Config.lang("Sai_sot_cong")}
                >
                    <ButtonGeneral
                        name={Config.lang('Them')}
                        typeButton={'add'}
                        style={{textTransform: 'uppercase'}}
                        size={"large"}
                        disabled={iPermission < 1}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{border: 'none'}}
                    dataSource={dataGrid.rows}
                    totalItems={dataGrid.total}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("No_data")}
                    onDbCellClick={this.onEdit}
                    loading={loadingDataGrid}
                    hoverStateEnabled={true}
                    height={Config.getHeightGrid()}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile}/>
                    <Column cellRender={this.renderInfo}/>
                    <Column cellRender={this.renderStatus}/>
                    <Column cellRender={this.renderAction}/>
                </GridContainer>
            </>
        );
    }

    componentDidMount = () => {
        this.loadPermission();
        this.loadDataGrid();
        if (this.state.iPermission <= 0) return;
    }
}

export default compose(connect((state) => ({
    getCboEmployees: state.general.getCboEmployees,
    getCboProjects: state.general.getCboProjects,
    getCboAppStatus: state.general.getCboAppStatus,
    getCboPeriod: state.W75F2010.getCboPeriod,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    W75F2010Actions: bindActionCreators(W75F2010Actions, dispatch)
})), withStyles(styles))(W52F1000);