/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 5/25/2020
 * @update 5/25/2020
 * @Example
 */
import React, { Component } from "react";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as generalActions from "../../../redux/general/general_actions";
import ActionToolbar from "../../common/toolbar/action-toolbar";
import Config from "../../../config";
import ButtonCustom from "../../common/button/button-custom";
import ButtonGeneral from "../../common/button/button-general";
import { Image, FormGroup, Row, Col } from "react-bootstrap";
import GridContainer from "../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Divider from "@material-ui/core/Divider";
import Filter from "../../filter/filter";
import { Combo } from "../../common/form-material";
import DateBoxPicker from "../../common/form-material/date-box";
import moment from "moment";
import UserImage from "../../common/user/user-image";
// import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
// import Zoom from "@material-ui/core/Zoom";

import Api from "../../../services/api";
import Icons from "../../common/icons/";

const styles = {
    divAvatar: {
        width: 45,
        height: 45,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757",
    },
    divTextTooltip: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#fffff",
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
    },
};

class W51F1000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            cboLoading: false,
            dataGrid: { total: 0, rows: [] },
            dataCboEmployees: { total: 0, rows: [] },
            isPermission: 0,
            DateFrom: moment().startOf('year').format("YYYY-MM-DD"),
            DateTo: moment().endOf('year').format("YYYY-MM-DD"),
        };
        this.filter = {
            employeeID: "",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }

    redirectScreen = (mode = "add", e = "") => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W51F1001",
            state: {
                mode: mode,
                TransGroupID: _.get(e, "data.TransGroupID", ""),
                isPermissionForm: this.state.isPermission,
            },
        });
    };

    handleResponse = (res, updateState = false) => {
        const code = _.get(res, "code", 0) === 200;
        const message = _.get(res, "message", Config.lang("Loi_chua_xac_dinh"));
        const data = _.get(res, "data", false);
        if (!code) Config.popup.show("INFO", message);
        if (data && updateState) this.setState({ [updateState]: data });
    };

    handleFilter = (key, data) => {
        const stateFieldName = ["DateFrom", "DateTo"];
        let value = _.get(data, "value", "");
        if (stateFieldName.includes(key)) {
            this.setState({
                [key]: value,
            });
            return;
        }
        if (key === "employeeID") value = _.get(value, "EmployeeID", "");
        this.filter[key] = value;
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W51F1000", (isPer) => {
            this.setState({ isPermission: isPer });
        });
    };

    loadDataGrid = async () => {
        const { DateTo, DateFrom } = this.state;
        const { skip, limit, employeeID } = this.filter;
        const params = {
            FormID: "W51F1000",
            Language: Config.language || "84",
            EmployeeID: employeeID,
            skip,
            limit,
            DateTo,
            DateFrom,
        };
        this.setState({ gridLoading: true });
        const res = await Api.put("/w51f1000/load-form-list", params);
        this.handleResponse(res, "dataGrid");
        this.setState({
            gridLoading: false,
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W51F1000",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ cboLoading: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ cboLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onDelete = (data) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            (async () => {
                const params = { TransGroupID: _.get(data, "TransGroupID", "") };
                this.setState({ gridLoading: true });
                const res = await Api.put("/w51f1000/delete", params);
                const status = _.get(res, "data.Status", 1);
                if (status) {
                    Config.popup.show("INFO", Config.lang("Ton_tai_nhan_vien_duoc_cap_phat_,_khong_duoc_phep_xoa"));
                } else {
                    Config.popup.show("INFO", Config.lang("Xoa_thanh_cong"));
                    this.loadDataGrid();
                }
                this.setState({ gridLoading: false });
            })();
        });
    };

    renderFilter = () => {
        const { classes } = this.props;
        const { dataCboEmployees, DateTo, DateFrom, cboLoading } = this.state;
        return (
            <Filter
                placeholder={Config.lang("Tim_kiem_cap_phat_trang_thiet_bi_lao_dong")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>{Config.lang("Tu")}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{ max: DateTo }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={(e) => this.handleFilter("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateFrom}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>{Config.lang("Den").toLowerCase()}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: DateFrom }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={(e) => this.handleFilter("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateTo}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={this.filter.employeeID === "%" ? null : this.filter.employeeID}
                                            stylingMode={"outlined"}
                                            loading={cboLoading}
                                            label={Config.lang("Nhan_vien")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilter("employeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.loadDataGrid}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    renderEmpProfile = (e) => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} />
            </div>
        );
    };

    renderEmployeeInfo = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        let getUserName = "";
        let projectName = _.get(data, "ProjectName", "");
        const employeeID = _.get(data, "EmployeeID", false);
        const dateCreated = moment(_.get(data, "CreateDate", "")).format("LT, DD/MM/YYYY");
        if (employeeID) {
            getUserName = _.get(Config.getUser({ EmployeeID: employeeID }), "EmployeeName", "");
        }

        return (
            <div className={"w75f2000_info"}>
                <div className={classes.divText}>{getUserName}</div>
                <div className={classes.divText}>{projectName}</div>
                <div className={classes.dateInfo}>
                    <Image src={require("../../../assets/images/icon-calendar.svg")} />
                    <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                        {dateCreated}
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        const examineDate = moment.utc(_.get(data, "ExamineDate", "")).format("DD/MM/YYYY");
        const wareHouseId = _.get(data, "WareHouseID", false);
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>
                    {Config.lang("Ngay_thuc_hien")}:{examineDate}
                </div>
                <div>
                    {Config.lang("Ly_do")}: {_.get(data, "NoteU", "")}
                </div>
                <div>
                    {Config.lang("Tong_so_nhan_vien")}: {_.get(data, "QuantityEmp", "")}
                </div>
                {wareHouseId && (
                    <div>
                        {Config.lang("Kho")}: {wareHouseId}
                    </div>
                )}
            </div>
        );
    };

    renderAction = (e) => {
        const { data } = e.row;
        const { gridLoading } = this.state;
        return (
            <div className={"display_row align-center"}>
                <IconButton disabled={gridLoading} aria-label="view" size="small" onClick={() => this.redirectScreen("edit", e)}>
                    <Icons name={"view"}/>
                </IconButton>
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: "0 5px" }} />
                <IconButton disabled={gridLoading} aria-label="delete" size="small" onClick={() => this.onDelete(data)}>
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        );
    };

    render() {
        const { skip, limit } = this.filter;
        const { gridLoading, dataGrid } = this.state;
        const dataGridRows = _.get(dataGrid, "rows", []);
        const dataGridTotal = _.get(dataGrid, "total", 0);
        return (
            <>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Cap_phat_thiet_bi_lao_dong")}>
                    <ButtonCustom
                        name={Config.lang("Cap_phat")}
                        icon={<ExitToAppIcon />}
                        color={"primary"}
                        className={"mgr10"}
                        variant={"custom"}
                        disabled={gridLoading}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={this.redirectScreen}
                    />
                </ActionToolbar>
                <GridContainer
                    allowColumnResizing={true}
                    style={{ border: "none" }}
                    dataSource={dataGridRows}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("No_data")}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={dataGridTotal}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column width={120} cellRender={this.renderEmpProfile} />
                    <Column width={250} cellRender={this.renderEmployeeInfo} />
                    <Column width={650} cellRender={this.renderInfo} />
                    <Column cellRender={this.renderAction} />
                </GridContainer>
            </>
        );
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.isPermission <= 0) return;
        this.loadCboEmployees();
        this.loadDataGrid();
    };
}

export default compose(
    connect(
        (state) => ({ getCboEmployees: state.general.getCboEmployees }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W51F1000);
